function toArray(arrayLikeObj) {
  if (!arrayLikeObj) return [];

  return Array.prototype.slice.call(arrayLikeObj);
}

function extend(destObject) {
  var args = toArray(arguments);
  var dest;

  if (args.length == 1) {
    return destObject;
  }

  args.shift();

  // 从前往后遍历
  for (var i = 0, l = args.length; i < l; i++) {
    for (var key in args[i]) {
      if (args[i].hasOwnProperty(key)) {
        destObject[key] = args[i][key];
      }
    }
  }

  return destObject;
}

function isTypeOf(something, type) {
  if (!type) return false;

  type = type.toLowerCase();

  var realTypeString = Object.prototype.toString.call(something);

  return realTypeString.toLowerCase() === '[object ' + type + ']';
}

function isArray(something) {
  return isTypeOf(something, 'array');
}

function isFunction(something) {
  return typeof something === 'function';
}

function isString(something) {
  return typeof something === 'string';
}

function isDefined(something) {
  return !(typeof something === 'undefined');
}

function isObject(something) {
  return typeof something === 'object';
}

function isReg(something) {
  return isTypeOf(something, 'regexp');
}

/**
 *
 * @param {Function/String/RegExp} rule
 * @param {String}                 entryName
 * @return {Boolean}
 */
function isThisWhatYouNeed(rule, entryName) {
  return isFunction(rule) ? rule(entryName) :
    isString(rule) ? entryName.toLowerCase().indexOf(rule.toLowerCase()) > -1 :
      isReg(rule) ? rule.test(entryName.toLowerCase()) :
        false;
}

/**
 *
 * @param str
 * @param prefix
 * @returns {boolean}
 */
function startWith(str, prefix) {
  return str.indexOf(prefix) === 0;
}

function isResouces(attrValue) {
  return startWith(attrValue, 'resourceId:');
}

function transKeyToMatchResourceMap(resourceId) {
  return '@' + resourceId.replace('resourceId:0x', '').toUpperCase();
}

function castLogger(doWhat, fromWhen) {
  console.log(doWhat + ' cost: ' + (Date.now() - fromWhen) + 'ms');
}

module.exports = {
  toArray: toArray,
  extend: extend,
  startWith: startWith,
  isResouces: isResouces,
  transKeyToMatchResourceMap: transKeyToMatchResourceMap,
  castLogger: castLogger,
  isTypeOf: isTypeOf,
  isArray: isArray,
  isFunction: isFunction,
  isString: isString,
  isDefined: isDefined,
  isObject: isObject,
  isReg: isReg,
  isThisWhatYouNeed: isThisWhatYouNeed
};
